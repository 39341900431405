<template>
  <div class="login">
    <div class="location">
      <i class="el-icon-place"></i>
      <el-breadcrumb
        class="el__breadcrumb"
        separator-class="el-icon-arrow-right"
      >
        <el-breadcrumb-item>您当前的位置</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>登录</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="content">
      <ul class="tabs">
        <li
          v-for="(item, index) in tabs"
          :key="index"
          :class="{ current: item.value == current }"
          @click="onItemClick(item)"
        >
          {{ item.key }}
        </li>
      </ul>
      <code-login v-if="current == 1"></code-login>
      <user-login v-else></user-login>
      <div class="more">
        <span @click="forgetpwd">忘记密码</span>
        <div @click="onReg">还没有账号？<span>马上注册>></span></div>
      </div>
    </div>
  </div>
</template>

<script>
import CodeLogin from "@/components/CodeLogin";
import UserLogin from "@/components/UserLogin";
export default {
  name: "login",
  components:{
    CodeLogin,
    UserLogin
  },
  data() {
    return {
      current: 1,
      tabs: [
        {
          key: "快捷登录",
          value: 1,
        },
        {
          key: "账号密码登录",
          value: 2,
        },
      ],
    };
  },
  methods: {
    forgetpwd() {
      this.$router.push("/web/forget");
    },
    onReg() {
      this.$router.push("/web/reigster");
    },
    onItemClick(item) {
      this.current = item.value;
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  background: #ffffff;
  .location {
    padding: 20px;
    display: flex;
    align-items: center;
    .el__breadcrumb {
      margin-left: 8px;
    }
  }
  .content {
    width: 410px;
    padding: 50px 358px 180px;
    .tabs {
      display: flex;
      li {
        font-size: 16px;
        color: #999999;
        margin-right: 26px;
        padding: 8px 0;
        &.current {
          font-weight: 600;
          color: #333333;
          border-bottom: 4px solid #5cbd31;
        }
      }
    }
    .more {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 15px;
      color: #333333;
      span {
        color: #5cbd31;
      }
    }
  }
}
</style>