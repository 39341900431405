<template>
  <el-form class="el__form" ref="form" :model="form" :rules="rules">
    <el-form-item prop="username">
      <el-input
        prefix-icon="el-icon-user"
        placeholder="请输入账号"
        v-model="form.username"
      ></el-input>
    </el-form-item>
    <el-form-item prop="password">
      <el-input
        prefix-icon="el-icon-lock"
        placeholder="请输入密码"
        v-model="form.password"
        show-password
      ></el-input>
    </el-form-item>
    <el-form-item prop="captchaCode">
      <el-input
        prefix-icon="el-icon-key"
        placeholder="请输入图片验证码"
        v-model="form.captchaCode"
        class="captcha__code"
      >
        <el-image
          slot="append"
          @click="getCaptchaCode"
          :src="form.captchaImg"
        ></el-image>
      </el-input>
    </el-form-item>
    <el-form-item>
      <el-button class="el__btn" @click="submit('form')">登录</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { getCaptcha, loginByUsername,getUserDeatil } from "@/api/outh";
import { mapState, mapActions } from "vuex";
// import { validatePassStrength } from "@/utils/tools.js";

export default {
  computed: {
    ...mapState({
      userInfo: (state) => state.userInfo,
    }),
  },
  data() {
    return {
      form: {
        username: "",
        password: "",
        captchaCode: "",
        captchaKey: "",
        captchaImg: "",
      },
      rules: {
        username: [{ required: true, message: "请输入账号", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        captchaCode: [
          { required: true, message: "请输入图片验证码", trigger: "blur" },
        ],
      },
    };
  },
  mounted() {
    this.getCaptchaCode();
  },
  methods: {
    ...mapActions(["setUserInfo"]),
    async getCaptchaCode() {
      const res = await getCaptcha();
      // console.log(res);
      this.form.captchaImg = res.image;
      this.form.captchaKey = res.key;
    },
    submit(formName) {
      const { captchaKey, captchaCode, username, password } = this.form;

      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.getCaptchaCode();
          const res = await loginByUsername(
            captchaKey,
            captchaCode,
            username,
            password
          );
          // console.log(res);
          this.$message.success("登录成功");
          this.setUserInfo(res);
          let userParams = {
							id: res.user_id
						}
          let userDetail = await getUserDeatil(userParams)
          res.isCloseSpeak = userDetail.data.isCloseSpeak

          this.setUserInfo(res);

          setTimeout(() => {
            this.$router.replace("/web/index");
            // validatePassStrength("", password, (msg) => {
            //    if (msg) {
            //     this.$confirm("密码强度太低，请修改", "提示", {
            //       confirmButtonText: "确定",
            //       cancelButtonText: "取消",
            //       type: "warning",
            //     })
            //       .then(() => {
            //         this.$router.push({
            //           path: "/web/personalCenter",
            //           query: {
            //             editPwd: 1,
            //           },
            //         });
            //       })
            //       .catch(() => {
            //         this.$router.replace("/web/index");
            //         // this.$router.go(-1);
            //       });
            //    }
            // });
            
          }, 500);
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.el__form {
  margin-top: 24px;
  .el-input {
    width: 410px;
  }
  /deep/ .captcha__code {
    .el-input-group__append {
      background-color: #ffffff;
    }
    .el-image {
      width: 90px;
    }
  }
  .el__btn {
    width: 410px;
    background: #5cbd31;
    border: 0;
    color: #ffffff;
  }
}
</style>
